export const DEVICE_11 = '11'
export const DEVICE_12 = '12'
export const DEVICE_13 = '13'
export const DEVICE_14 = '16'
export const DEVICE_19 = '19'
export const DEVICE_21 = '21'
export const DEVICE_22 = '22'
export const DEVICE_23 = '23'
export const DEVICE_24 = '24'
export const DEVICE_25 = '25'
export const DEVICE_26 = '26'
export const DEVICE_31 = '31'
export const DEVICE_32 = '32'
export const DEVICE_41 = '41'
export const DEVICE_42 = '42'
export const DEVICE_43 = '43'
export const DEVICE_44 = '44'
export const DEVICE_45 = '45'
export const DEVICE_46 = '46'
export const DEVICE_47 = '47'
export const DEVICE_48 = '48'
export const DEVICE_49 = '49'
export const DEVICE_51 = '51'
export const DEVICE_52 = '52'
export const DEVICE_53 = '53'
export const DEVICE_61 = '61'
export const DEVICE_62 = '62'
export const DEVICE_63 = '63'
export const DEVICE_64 = '64'
export const DEVICE_71 = '71'
export const DEVICE_72 = '72'
export const DEVICE_73 = '73'
export const DEVICE_81 = '81'
export const DEVICE_82 = '82'
export const DEVICE_83 = '83'
export const DEVICE_84 = '84'
export const DEVICE_91 = '91'

export const RID_PRESSURE_DEVICES = [
  DEVICE_51,
  DEVICE_52,
  DEVICE_53,
  DEVICE_61,
  DEVICE_62,
  DEVICE_63,
  DEVICE_71,
  DEVICE_72,
  DEVICE_73,
  DEVICE_81,
  DEVICE_82,
  DEVICE_83,
] as const
export type RidPressureDeviceType = typeof RID_PRESSURE_DEVICES[number]

export const NON_RID_PRESSURE_DEVICES = [
  DEVICE_11,
  DEVICE_12,
  DEVICE_13,
  DEVICE_14,
  DEVICE_19,
  DEVICE_21,
  DEVICE_22,
  DEVICE_23,
  DEVICE_24,
  DEVICE_25,
  DEVICE_26,
  DEVICE_31,
  DEVICE_32,
  DEVICE_41,
  DEVICE_42,
  DEVICE_43,
  DEVICE_44,
  DEVICE_45,
  DEVICE_46,
  DEVICE_47,
  DEVICE_48,
  DEVICE_49,
  DEVICE_64,
  DEVICE_84,
  DEVICE_91,
] as const
export type NonRidPressureDeviceType = typeof NON_RID_PRESSURE_DEVICES[number]

export const ALL_PRESSURE_DEVICE_TYPES = [
  ...RID_PRESSURE_DEVICES,
  ...NON_RID_PRESSURE_DEVICES,
] as const

export type PressureDeviceType = typeof ALL_PRESSURE_DEVICE_TYPES[number]
