import type {QueryFunctionContext, UseMutationOptions, UseQueryOptions} from '@tanstack/react-query'
import {onlineManager, QueryClient, QueryClientProvider, useMutation} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import getInvalidatedAppResources from 'common/invalidationMap'
import type {JSONValue} from 'common/schemas'
import type {AppResource} from 'common/schemas/common'
import {generateResourcePath} from 'constants/routes'
import * as routes from 'constants/routes'
import {debounce, map, memoize} from 'lodash'
import type {FC, ReactNode} from 'react'
import {useCallback, useEffect, useState} from 'react'
import {api, sse} from '../utils/api'
import type {Options, FrontendError, SSEOptions} from '../utils/api'
import downloadFileFn from '../utils/downloadFile'
import {readSession, useSession} from './auth'


const client = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: true,
      retry: (failureCount, error: FrontendError) => {
        const status = error?.data?.status
        if (status && status < 500) return false
        return failureCount < 3
      },
      staleTime: Infinity, // Invalidated using SSE
      cacheTime: Infinity,
    },
  },
})

type ApiProviderProps = {
  children: ReactNode
}

export const ApiProvider: FC<ApiProviderProps> = ({children}) => {
  return (
    <QueryClientProvider client={client}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}


type OneQuery = {
  appResource: AppResource
  id: number
}

// Queries

const createOneQueryKey = ({appResource: {app, resource}, id}: OneQuery) => {
  return [app, resource, {params: {id}}] as const
}

const createOneQueryFn = <TValue, >(ctx: QueryFunctionContext<ReturnType<typeof createOneQueryKey>>) => {
  const [app, resource, options] = ctx.queryKey
  const route = generateResourcePath(app || '', resource, ':id')
  const session = readSession()

  return api<TValue>('GET', route, {sessionToken: session?.token, signal: ctx.signal, ...options})
}

export const createOneQuery = <TValue, >({appResource, id}: OneQuery) => {
  const queryKey = createOneQueryKey({appResource, id})

  return {
    queryKey,
    queryFn: createOneQueryFn<TValue>,
  } satisfies UseQueryOptions
}

type ListQuery = {
  appResource: AppResource
  query?: Options['query']
}

const createListQueryKey = ({appResource: {app, resource}, query}: ListQuery) => {
  if (!query) return [app, resource] as const

  const options = {
    query,
  } satisfies Options

  return [app, resource, options] as const
}


const createListQueryFn = <TValue, >(ctx: QueryFunctionContext<ReturnType<typeof createListQueryKey>>) => {
  const [app, resource, options] = ctx.queryKey
  const route = generateResourcePath(app || '', resource)
  const session = readSession()

  return api<TValue>('GET', route, {sessionToken: session?.token, signal: ctx.signal, ...options})
}

export const createListQuery = <TValue, >({appResource, query}: ListQuery) => {
  const queryKey = createListQueryKey({
    appResource,
    query,
  })

  return {
    queryKey,
    queryFn: createListQueryFn<TValue>,
    keepPreviousData: true,
  } satisfies UseQueryOptions
}

export const invalidateResource = async (appResource: AppResource, queryClient: QueryClient) => {
  await queryClient.invalidateQueries({
    queryKey: createListQueryKey({appResource}),
    type: 'all',
    refetchType: 'active',
  })

  const dependentAppResources = getInvalidatedAppResources(appResource)

  await Promise.all(dependentAppResources.map(async (dependentAppResource) => {
    await queryClient.invalidateQueries({
      queryKey: createListQueryKey({appResource: dependentAppResource}),
      type: 'all',
      refetchType: 'active',
    })
  }))
}

// Mutations

type SupportedMutationOptions = Partial<Pick<UseMutationOptions, 'retry' | 'retryDelay' | 'meta' | 'useErrorBoundary'>>

type CreateResource<TUnwrap> = {
  appResource: AppResource
  config?: SupportedMutationOptions
  unwrapData?: TUnwrap
}

type CreateResponse = {
  id: number
}

export const useCreateResource = <TUnwrap extends boolean>(
  {appResource: {app, resource}, config = {}, unwrapData}: CreateResource<TUnwrap>,
) => {
  const session = useSession()
  const route = generateResourcePath(app || '', resource)
  const options = {
    sessionToken: session ? session.token : undefined,
  }
  const mutation = useMutation((data: TUnwrap extends true ? Options : Options['data']) => {
    return api<CreateResponse>('POST', route, {...options, ...(unwrapData ? data : {data})})
  }, {
    mutationKey: ['POST', route],
    useErrorBoundary: undefined,
    ...config,
  })
  return mutation
}

type UpdateResource<TUnwrap> = {
  appResource: AppResource
  id: number | string
  config?: SupportedMutationOptions
  unwrapData?: TUnwrap
} | {
  appResource: AppResource
  id?: never
  config?: SupportedMutationOptions
  unwrapData?: true
}

type UpdateResponse = {
  id: number
}

export const useUpdateResource = <TUnwrap extends boolean>(
  {appResource: {app, resource}, id, config = {}, unwrapData}: UpdateResource<TUnwrap>,
) => {
  const session = useSession()
  const route = generateResourcePath(app || '', resource, id || ':resourceId')
  const options = {
    sessionToken: session ? session.token : undefined,
  }
  const mutation = useMutation((data: TUnwrap extends true ? Options : Options['data']) => {
    return api<UpdateResponse>('PUT', route, {...options, ...(unwrapData ? data : {data})})
  }, {
    mutationKey: ['PUT', route],
    useErrorBoundary: undefined,
    ...config,
  })
  return mutation
}

type DeleteResource = {
  appResource: AppResource
  config?: SupportedMutationOptions
}

type DeleteResponse = {
  id: number
}

export const useDeleteResource = (
  {appResource: {app, resource}, config = {}}: DeleteResource,
) => {
  const session = useSession()
  const route = generateResourcePath(app || '', resource, ':resourceId')
  const options = {
    sessionToken: session ? session.token : undefined,
  }
  const mutation = useMutation((id: string | number) => {
    const params = {resourceId: id} as const
    return api<DeleteResponse>('DELETE', route, {...options, params})
  }, {
    mutationKey: ['DELETE', route],
    useErrorBoundary: undefined,
    ...config,
  })
  return mutation
}

type DeleteManyResource = {
  appResource: AppResource
}

export const useDeleteManyResources = ({appResource}: DeleteManyResource) => {
  const deleteResource = useDeleteResource({appResource})

  const mutateAsync = async (ids: (string | number)[]) => {
    await Promise.all(map(ids, (id) => (
      deleteResource.mutateAsync(id)
    )))
  }
  return {...deleteResource, mutateAsync}
}

// SSE

export const useSSE = (route: string, options?: SSEOptions) => {
  const [_error, setError] = useState<Error | undefined>()
  const session = useSession()
  const sessionToken = session ? session.token : undefined
  useEffect(() => {
    const abortController = new AbortController()
    // eslint-disable-next-line no-void
    sse('GET', route, {
      ...options,
      sessionToken,
      signal: abortController.signal,
    })
      .catch((err) => {
        // Hack to enable react to catch the error in ErrorBoundary
        setError(() => {
          throw err
        })
      })
    return () => {
      abortController.abort()
    }
  }, [route, options, sessionToken])
}


export type SearchMethod = 'name' | 'companyNumber'

export const useCompanyInfo = <TValues, >(searchMethod: SearchMethod) => {
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<TValues | undefined>()
  const session = useSession()


  // UseCallback can't be inside memoize function because it could cause multiple unnecessary requests
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCompanies = useCallback(memoize(async (value: string) => {
    return await api<TValues>('GET', routes.API_GET_COMPANY_INFO, {
      sessionToken: session ? session.token : undefined,
      query: {
        [searchMethod]: value,
      },
    })
  }), [searchMethod, session])

  // UseCallback can't determine dependencies when wrapped in debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchCompanies = useCallback(debounce(async (value: string, enabled?: boolean) => {
    setLoading(true)
    if (!enabled) {
      setLoading(false)
      setCompanies(undefined)
      return
    }
    const res = await loadCompanies(value)
    setCompanies(res.data)
    setLoading(false)
  }, 800), [loadCompanies])

  return {companies, searchCompanies, loading}
}

type DownloadOptions = {
  onSuccess?: (filename: string) => void
  onError?: (e: unknown) => void
}

export const useDownload = (
  route: string,
  options: DownloadOptions,
) => {
  const session = useSession()
  const [loading, setLoading] = useState(false)

  const downloadFile = (query: Record<string, JSONValue>, filenameOverride?: string) => async () => {
    setLoading(true)
    try {
      const res = await api<Blob>('GET', route, {query, sessionToken: session?.token, asBlob: true})
      const filename = filenameOverride || res.filename || ''
      const fileBlob = res.data
      if (!fileBlob) return null

      downloadFileFn(fileBlob, filename)

      if (options.onSuccess) {
        options.onSuccess(filename)
      }
    } catch (e) {
      if (options.onError) {
        options.onError(e)
      }
    } finally {
      setLoading(false)
    }
  }


  return {downloadFile, loading}
}

export const useOnline = () => {
  const [online, setOnline] = useState(onlineManager.isOnline())
  useEffect(() => {
    return onlineManager.subscribe(() => {
      setOnline(onlineManager.isOnline())
    })
  }, [])
  return online
}
