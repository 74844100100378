export const DEVICE_10 = '10'
export const DEVICE_21 = '21'
export const DEVICE_22 = '22'
export const DEVICE_31 = '31'
export const DEVICE_32 = '32'
export const DEVICE_33 = '33'
export const DEVICE_34 = '34'
export const DEVICE_35 = '35'
export const DEVICE_36 = '36'
export const DEVICE_41 = '41'
export const DEVICE_42 = '42'
export const DEVICE_43 = '43'
export const DEVICE_51 = '51'
export const DEVICE_52 = '52'
export const DEVICE_53 = '53'
export const DEVICE_54 = '54'
export const DEVICE_55 = '55'
export const DEVICE_56 = '56'
export const DEVICE_57 = '57'
export const DEVICE_61 = '61'
export const DEVICE_62 = '62'
export const DEVICE_63 = '63'
export const DEVICE_81 = '81'
export const DEVICE_82 = '82'
export const DEVICE_83 = '83'
export const DEVICE_84 = '84'
export const DEVICE_85 = '85'
export const DEVICE_86 = '86'
export const DEVICE_87 = '87'
export const DEVICE_90 = '90'

export const ALL_GAS_DEVICE_TYPES = [
  DEVICE_10,
  DEVICE_21,
  DEVICE_22,
  DEVICE_31,
  DEVICE_32,
  DEVICE_33,
  DEVICE_34,
  DEVICE_35,
  DEVICE_36,
  DEVICE_41,
  DEVICE_42,
  DEVICE_43,
  DEVICE_51,
  DEVICE_52,
  DEVICE_53,
  DEVICE_54,
  DEVICE_55,
  DEVICE_56,
  DEVICE_57,
  DEVICE_61,
  DEVICE_62,
  DEVICE_63,
  DEVICE_81,
  DEVICE_82,
  DEVICE_83,
  DEVICE_84,
  DEVICE_85,
  DEVICE_86,
  DEVICE_87,
  DEVICE_90,
] as const

export type GasDeviceType = typeof ALL_GAS_DEVICE_TYPES[number]
