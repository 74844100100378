import type {z} from 'zod'
import * as types from './types'


export const login = types.optionalObject({
  email: types.requiredString('admin@example.com'),
  password: types.requiredString('nbusr123'),
  remember: types.boolean(false),
})
export type Login = z.infer<typeof login>
