export const L0 = 'L0'
export const L1 = 'L1'
export const L2 = 'L2'
export const L3 = 'L3'

export const ALL_ETCS_TYPES = [
  L0,
  L1,
  L2,
  L3,
] as const

export type EtcsType = typeof ALL_ETCS_TYPES[number]
