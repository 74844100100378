export const EDIT_COMPANIES = 'EDIT_COMPANIES'
export const EDIT_FOREIGN_CARRIERS = 'EDIT_FOREIGN_CARRIERS'
export const EDIT_LICENSES = 'EDIT_LICENSES'
export const EDIT_PERMITS = 'EDIT_PERMITS'
export const EDIT_TRACKS = 'EDIT_TRACKS'
export const EDIT_TRACK_CANCELLATION = 'EDIT_TRACK_CANCELLATION'
export const EDIT_SUPERVISIONS = 'EDIT_SUPERVISIONS'
export const EDIT_USERS = 'EDIT_USERS'
export const EDIT_APPEALS = 'EDIT_APPEALS'
export const EDIT_FINES = 'EDIT_FINES'
export const EDIT_CATEGORY_CHANGES = 'EDIT_CATEGORY_CHANGES'
export const EDIT_LICENSE_CHANGES = 'EDIT_LICENSE_CHANGES'
export const EDIT_PERMIT_CHANGES = 'EDIT_PERMIT_CHANGES'
export const EDIT_TRACK_CHANGES = 'EDIT_TRACK_CHANGES'
export const EDIT_ELECTRONICS = 'EDIT_ELECTRONICS'
export const EDIT_MANUFACTURERS = 'EDIT_MANUFACTURERS'
export const EDIT_DEVICES = 'EDIT_DEVICES'
export const EDIT_PRESSURES = 'EDIT_PRESSURES'
export const EDIT_LIFTS = 'EDIT_LIFTS'
export const EDIT_CABLES = 'EDIT_CABLES'
export const EDIT_GASES = 'EDIT_GASES'

export const MANAGE_COMPANIES = 'MANAGE_COMPANIES'
export const MANAGE_FOREIGN_CARRIERS = 'MANAGE_FOREIGN_CARRIERS'
export const MANAGE_LICENSES = 'MANAGE_LICENSES'
export const MANAGE_PERMITS = 'MANAGE_PERMITS'
export const MANAGE_TRACKS = 'MANAGE_TRACKS'
export const MANAGE_TRACK_CANCELLATION = 'MANAGE_TRACK_CANCELLATION'
export const MANAGE_SUPERVISIONS = 'MANAGE_SUPERVISIONS'
export const MANAGE_USERS = 'MANAGE_USERS'
export const RESTORE_TRACKS = 'RESTORE_TRACKS'
export const MANAGE_APPEALS = 'MANAGE_APPEALS'
export const MANAGE_FINES = 'MANAGE_FINES'
export const MANAGE_CATEGORY_CHANGES = 'MANAGE_CATEGORY_CHANGES'
export const MANAGE_LICENSE_CHANGES = 'MANAGE_LICENSE_CHANGES'
export const MANAGE_PERMIT_CHANGES = 'MANAGE_PERMIT_CHANGES'
export const MANAGE_TRACK_CHANGES = 'MANAGE_TRACK_CHANGES'
export const MANAGE_ELECTRONICS = 'MANAGE_ELECTRONICS'
export const MANAGE_MANUFACTURERS = 'MANAGE_MANUFACTURERS'
export const MANAGE_DEVICES = 'MANAGE_DEVICES'
export const MANAGE_PRESSURES = 'MANAGE_PRESSURES'
export const MANAGE_LIFTS = 'MANAGE_LIFTS'
export const MANAGE_CABLES = 'MANAGE_CABLES'
export const MANAGE_GASES = 'MANAGE_GASES'

export const ALL_PERMISSIONS = [
  EDIT_COMPANIES,
  EDIT_FOREIGN_CARRIERS,
  EDIT_LICENSES,
  EDIT_PERMITS,
  EDIT_TRACKS,
  EDIT_TRACK_CANCELLATION,
  EDIT_SUPERVISIONS,
  EDIT_USERS,
  EDIT_APPEALS,
  EDIT_FINES,
  EDIT_CATEGORY_CHANGES,
  EDIT_LICENSE_CHANGES,
  EDIT_PERMIT_CHANGES,
  EDIT_TRACK_CHANGES,
  EDIT_ELECTRONICS,
  EDIT_MANUFACTURERS,
  EDIT_DEVICES,
  EDIT_PRESSURES,
  EDIT_LIFTS,
  EDIT_CABLES,
  EDIT_GASES,

  MANAGE_COMPANIES,
  MANAGE_FOREIGN_CARRIERS,
  MANAGE_LICENSES,
  MANAGE_PERMITS,
  MANAGE_TRACKS,
  MANAGE_TRACK_CANCELLATION,
  MANAGE_SUPERVISIONS,
  MANAGE_USERS,
  RESTORE_TRACKS,
  MANAGE_APPEALS,
  MANAGE_FINES,
  MANAGE_CATEGORY_CHANGES,
  MANAGE_LICENSE_CHANGES,
  MANAGE_PERMIT_CHANGES,
  MANAGE_TRACK_CHANGES,
  MANAGE_ELECTRONICS,
  MANAGE_MANUFACTURERS,
  MANAGE_DEVICES,
  MANAGE_PRESSURES,
  MANAGE_LIFTS,
  MANAGE_CABLES,
  MANAGE_GASES,
] as const

export type Permission = typeof ALL_PERMISSIONS[number]
