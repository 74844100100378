import * as apps from 'constants/apps'
import * as trackCategories from 'constants/apps/olup/trackCategories'

// Global

export const COMPANIES = '/companies'
export const COMPANIES_CREATE = '/companies/create'
export const COMPANIES_EDIT = '/companies/:companyId'
export const HOME = '/'
export const PROFILE = '/profile'
export const ROLES = '/users/roles'
export const USERS = '/users'
export const USERS_CREATE = '/users/create'
export const USERS_EDIT = '/users/:userId'

// OLUP
export const OLUP = `/${apps.OLUP}`
export const OLUP_CATEGORY_CHANGE = `${OLUP}/categoryChanges`
export const OLUP_CATEGORY_CHANGE_CREATE = `${OLUP}/categoryChanges/create`
export const OLUP_CATEGORY_CHANGE_EDIT = `${OLUP}/categoryChanges/:categoryChangeId`
export const OLUP_APPEALS = `${OLUP}/appeals`
export const OLUP_APPEALS_CREATE = `${OLUP}/appeals/create`
export const OLUP_APPEALS_EDIT = `${OLUP}/appeals/:appealId`
export const OLUP_FINES = `${OLUP}/fines`
export const OLUP_FINES_CREATE = `${OLUP}/fines/create`
export const OLUP_FINES_EDIT = `${OLUP}/fines/:fineId`
export const OLUP_FOREIGN_CARRIERS = `${OLUP}/foreign-carriers`
export const OLUP_FOREIGN_CARRIERS_CREATE = `${OLUP}/foreign-carriers/create`
export const OLUP_FOREIGN_CARRIERS_EDIT = `${OLUP}/foreign-carriers/:foreignCarrierId`
export const OLUP_LICENSES = `${OLUP}/licenses`
export const OLUP_LICENSE_CHANGES = `${OLUP}/licenses/changes`
export const OLUP_LICENSES_CREATE = `${OLUP}/licenses/create`
export const OLUP_LICENSES_EDIT = `${OLUP}/licenses/:licenseId`
export const OLUP_PERMITS = `${OLUP}/permits`
export const OLUP_PERMITS_CHANGES = `${OLUP}/permits/changes`
export const OLUP_PERMITS_CREATE = `${OLUP}/permits/create`
export const OLUP_PERMITS_EDIT = `${OLUP}/permits/:permitId`
export const OLUP_SUPERVISIONS = `${OLUP}/supervisions`
export const OLUP_TRACKS = `${OLUP}/tracks`
export const OLUP_TRACKS_CHANGES = `${OLUP}/tracks/:category/changes`
export const OLUP_TRACKS_CREATE = `${OLUP}/tracks/:category/create`
export const OLUP_TRACKS_EDIT = `${OLUP}/tracks/:trackId`
export const OLUP_TRACKS_LOCAL = `${OLUP}/tracks/local`
export const OLUP_TRACKS_NATIONAL = `${OLUP}/tracks/national`
export const OLUP_TRACKS_REGIONAL = `${OLUP}/tracks/regional`
export const OLUP_TRACKS_SIDINGS = `${OLUP}/tracks/siding`
export const OLUP_TRACKS_TEST = `${OLUP}/tracks/test`

export const OLUP_TRACKS_TYPE = {
  [trackCategories.LOCAL]: OLUP_TRACKS_LOCAL,
  [trackCategories.REGIONAL]: OLUP_TRACKS_REGIONAL,
  [trackCategories.NATIONAL]: OLUP_TRACKS_NATIONAL,
  [trackCategories.SIDING]: OLUP_TRACKS_SIDINGS,
  [trackCategories.TEST]: OLUP_TRACKS_TEST,
}
// UTZ
export const UTZ = `/${apps.UTZ}`
export const UTZ_DASHBOARD = `${UTZ}/dashboard`
export const UTZ_MANUFACTURER = `${UTZ}/manufacturer`
export const UTZ_DEVICES = `${UTZ}/electronicDevices/devices`
export const UTZ_DEVICES_CREATE = `${UTZ}/electronicDevices/devices/create`
export const UTZ_DEVICES_EDIT = `${UTZ}/electronicDevices/devices/:deviceId`
export const UTZ_ELECTRONIC_DEVICES = `${UTZ}/electronicDevices`
export const UTZ_ELECTRONIC_DEVICES_CREATE = `${UTZ}/electronicDevices/create`
export const UTZ_ELECTRONIC_DEVICES_EDIT = `${UTZ}/electronicDevices/:electronicId`
export const UTZ_LIFT_DEVICES = `${UTZ}/liftDevices`
export const UTZ_LIFT_DEVICES_CREATE = `${UTZ}/liftDevices/create`
export const UTZ_LIFT_DEVICES_EDIT = `${UTZ}/liftDevices/:liftId`
export const UTZ_PRESSURE_DEVICES = `${UTZ}/pressureDevices`
export const UTZ_PRESSURE_DEVICES_CREATE = `${UTZ}/pressureDevices/create`
export const UTZ_PRESSURE_DEVICES_EDIT = `${UTZ}/pressureDevices/:pressureId`
export const UTZ_CABLE_DEVICES = `${UTZ}/cableDevices`
export const UTZ_CABLE_DEVICES_CREATE = `${UTZ}/cableDevices/create`
export const UTZ_CABLE_DEVICES_EDIT = `${UTZ}/cableDevices/:cableId`
export const UTZ_GAS_DEVICES = `${UTZ}/gasDevices`
export const UTZ_GAS_DEVICES_CREATE = `${UTZ}/gasDevices/create`
export const UTZ_GAS_DEVICES_EDIT = `${UTZ}/gasDevices/:gasId`

export const getEJusticeUrl = (companyNumber: string) => {
  return `https://or.justice.cz/ias/ui/rejstrik-$firma?ico=${companyNumber}&jenPlatne=PLATNE&polozek=50&typHledani=STARTS_WITH`
}
export const getGinisUrl = (pid: string) => {
  return `https://gusu.ducr.cz/Gordic/Ginis/App/SPRhtml5/?c=OpenDetail&ixx1=${pid}`
}

export const getMapUrl = (x: number, y: number) => {
  return `https://mapy.cz/zakladni?x=${x}&y=${y}&z=10`
}

export const getCadastreUrl = (x: number, y: number) => {
  return `https://www.ikatastr.cz/#kde=${x},${y}`
}
