import {QueryErrorResetBoundary, useQuery} from '@tanstack/react-query'
import * as apps from 'constants/apps'
import {compact} from 'lodash'
import type {SnackbarKey} from 'notistack'
import {useSnackbar} from 'notistack'
import {Suspense, useEffect, useMemo} from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {createRoutesFromElements, Navigate, Route} from 'react-router'
import {Outlet, RouterProvider, createBrowserRouter, useRouteError} from 'react-router-dom'
import AppShell from './components/containers/AppShell'
import ErrorFallback from './components/screens/ErrorFallback'
import SplashScreen from './components/screens/SplashScreen'
import Login from './components/screens/login/Login'
import {ApiProvider, useOnline} from './hooks/api'
import {AuthProvider, useSession} from './hooks/auth'
import {AlertsProvider} from './hooks/useAlert'
import {MenuProvider, useMenu} from './hooks/useMenu'
import ThemeProvider from './theme/ThemeProvider'


const Shell = () => {
  const {openMenu, setOpenMenu} = useMenu()
  const menuClick = () => {
    setOpenMenu((prevOpen) => !prevOpen)
  }

  return (
    <AppShell onMenuClick={menuClick} openMenu={openMenu}>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </AppShell>
  )
}

const ErrorElement = () => {
  const error = useRouteError()
  throw error
}


const Screens = () => {
  const session = useSession()
  const isOnline = useOnline()
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const {data: routes} = useQuery({
    queryKey: ['getRoutes', session],
    queryFn: async () => {
      if (!session) return null
      const routeImports = await Promise.all([
        import('./components/screens'),
        session.apps?.includes(apps.OLUP) ? import('./apps/olup/screens') : null,
        session.apps?.includes(apps.UTZ) ? import('./apps/utz/screens') : null,
      ])
      return compact(routeImports.map((routeImport) => routeImport?.default))
    },
    suspense: true,
    keepPreviousData: false,
  })

  useEffect(() => {
    let snackbarKey: SnackbarKey | null = null
    if (!isOnline) {
      snackbarKey = enqueueSnackbar('Nemáte připojení k internetu nebo server je nedostupný', {
        variant: 'error',
        persist: true,
      })
    }
    return () => {
      if (snackbarKey) {
        closeSnackbar(snackbarKey)
        snackbarKey = null
      }
    }
  }, [isOnline, enqueueSnackbar, closeSnackbar])


  const router = useMemo(() => {
    if (session) {
      return createBrowserRouter([
        {
          id: 'root',
          element: <Shell />,
          errorElement: <ErrorElement />,
          children: createRoutesFromElements(
            <>
              {routes?.map((getRoutes) => getRoutes())}
            </>,
          ),
        },
      ])
    }

    return createBrowserRouter([
      {
        id: 'root',
        errorElement: <ErrorElement />,
        children: createRoutesFromElements(
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>,
        ),
      },
    ])
    // We don't use session?.token inside useMemo, but we still want
    // to rerender the router when the token changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.token])

  return (
    <RouterProvider router={router} />
  )
}


const App = () => (
  <QueryErrorResetBoundary>
    {({reset}) => (
      <ErrorBoundary
          onReset={reset}
          fallbackRender={({resetErrorBoundary}) => (
            <ErrorFallback resetError={resetErrorBoundary} />
          )}
      >
        <ThemeProvider>
          <AlertsProvider>
            <AuthProvider>
              <ApiProvider>
                <MenuProvider>
                  <Screens />
                </MenuProvider>
              </ApiProvider>
            </AuthProvider>
          </AlertsProvider>
        </ThemeProvider>
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
)


export default App
