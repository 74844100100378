export const NATIONWIDE = 'nationwide'
export const REGIONAL = 'regional'
export const LOCAL = 'local'
export const SIDING = 'siding'
export const EXPERIMENTAL = 'experimental'
export const NATIONWIDE_PLUS_SIDING = 'nationwide-plus-siding'
export const REGIONAL_PLUS_SIDING = 'regional-plus-siding'

export const ALL_MOUTHING_CATEGORIES = [
  NATIONWIDE,
  REGIONAL,
  LOCAL,
  SIDING,
  EXPERIMENTAL,
  NATIONWIDE_PLUS_SIDING,
  REGIONAL_PLUS_SIDING,
] as const

export type MouthingCategory = typeof ALL_MOUTHING_CATEGORIES[number]
