export const INTERRUPTED = 'interrupted'
export const STOPPED = 'stopped'
export const PAYMENT = 'payment'

export const ALL_CHANGE_STATES = [
  INTERRUPTED,
  STOPPED,
  PAYMENT,
] as const

export type ChangeState = typeof ALL_CHANGE_STATES[number]
