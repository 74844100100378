import {compact} from 'lodash'


export const API = '/api'
export const API_HEALTH_CHECK = '/healthz'
export const API_UPDATES = '/updates'

export const API_DOCS = '/api.json'
export const API_SWAGGER = '/docs'

export const API_LOGIN = '/login'
export const API_LOGOUT = '/logout'

export const EXPORT = 'export'

export const API_GET_COMPANY_INFO = '/company-info'

export const API_GET_VEHICLE_REGISTER = 'vehicle-register'

export const generateResourcePath = (...args: (string | number | null)[]) => `/${compact(args).join('/')}`
