import {Link as MuiLink} from '@mui/material'
import {styled} from '@mui/material/styles'
import type {ComponentProps, ReactNode, ElementType, CSSProperties} from 'react'


type LinkProps<TComponent extends ElementType> = ComponentProps<typeof MuiLink> & {
  fontWeight?: CSSProperties['fontWeight'],
  component?: TComponent,
  endIcon?: ReactNode,
} & Omit<ComponentProps<TComponent>, 'component'>


const StyledMuiLink = styled(MuiLink)<{fontWeight?: CSSProperties['fontWeight']}>(({theme, fontWeight}) => ({
  'display': 'inline-flex',
  'alignItems': 'center',
  'fontWeight': fontWeight,
  '> span': {
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  },
  '&:hover, &:focus': {
    '> span': {
      transform: 'translateX(5px)',
    },
  },
}))


const StyledEndIconWrapper = styled('span')(({theme}) => ({
  marginLeft: theme.spacing(0.3),
  display: 'inline-flex',
  alignItems: 'center',
}))

const Link = <TComponent extends ElementType = 'a'>({component, fontWeight, color = 'primary.main', endIcon, children, ...props}: LinkProps<TComponent>) => {
  return (
    <StyledMuiLink component={component} fontWeight={fontWeight} color={color} {...props}>
      {children}
      {endIcon ? (
        <StyledEndIconWrapper>
          {endIcon}
        </StyledEndIconWrapper>
      ) : null}
    </StyledMuiLink>
  )
}

export default Link
