export const SUMMER = 'summer'
export const WINTER = 'winter'
export const MIXED = 'mixed'

export const ALL_OPERATION_MODE_TYPES = [
  SUMMER,
  WINTER,
  MIXED,
] as const

export type OperationModeType = typeof ALL_OPERATION_MODE_TYPES[number]
