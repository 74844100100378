export const SYNTAX_ERROR = 'SYNTAX_ERROR'
export const VALIDATION_ERROR = 'VALIDATION_ERROR'
export const EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR'

export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR'
export const AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR'
export const INVALID_CREDENTIALS_ERROR = 'INVALID_CREDENTIALS_ERROR'
export const REFERENCE_ERROR = 'REFERENCE_ERROR'
export const PERMISSIONS_ERROR = 'PERMISSIONS_ERROR'
export const TRACK_ALREADY_REMOVED_ERROR = 'TRACK_ALREADY_REMOVED_ERROR'
export const NO_CONNECTION_TO_MAIN_TRACK_ERROR = 'NO_CONNECTION_TO_MAIN_TRACK_ERROR'
export const PERMITS_DIFFERENT_CATEGORY_ERROR = 'PERMITS_DIFFERENT_CATEGORY_ERROR'
export const TRACK_CATEGORY_MISMATCH = 'TRACK_CATEGORY_MISMATCH'
export const UNIQUE_SEQUENCE_ERROR = 'UNIQUE_SEQUENCE_ERROR'

export const INVALID_CONFIGURATION_ERROR = 'INVALID_CONFIGURATION_ERROR'
export const INVALID_SOFTWARE_VERSION_ERROR = 'INVALID_SOFTWARE_VERSION_ERROR'
export const ELECTRONIC_CONTINUITY_ERROR = 'ELECTRONIC_CONTINUITY_ERROR'

export const PG_UNIQUE_ERROR = '23505'
