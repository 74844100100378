export const REASON1 = 'reason1'
export const REASON2 = 'reason2'
export const REASON3 = 'reason3'
export const REASON4 = 'reason4'
export const REASON5 = 'reason5'
export const REASON6 = 'reason6'
export const REASON7 = 'reason7'
export const REASON8 = 'reason8'
export const REASON9 = 'reason9'
export const REASON10 = 'reason10'
export const REASON11 = 'reason11'

export const ALL_CHANGE_SUBJECT = [
  REASON1,
  REASON2,
  REASON3,
  REASON4,
  REASON5,
  REASON6,
  REASON7,
  REASON8,
  REASON9,
  REASON10,
  REASON11,
] as const

export type ChangeSubject = typeof ALL_CHANGE_SUBJECT[number]
