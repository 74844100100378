export const VEHICLE = 'vehicle'
export const SECURITY = 'security'
export const RECORDING = 'recording'
export const SPEEDOMETER = 'speedometer'
export const INTERFACE = 'interface'
export const RADIO_STATION = 'radio-station'
export const ETCS = 'etcs'

export const ALL_DEVICE_TYPES = [
  VEHICLE,
  SECURITY,
  RECORDING,
  SPEEDOMETER,
  INTERFACE,
  RADIO_STATION,
  ETCS,
] as const

export type DeviceType = typeof ALL_DEVICE_TYPES[number]
