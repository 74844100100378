export const RAILWAY_CRANES = 'railway-cranes'
export const MOTORIZED_RAILWAY_CRANES_AND_LIFTS = 'motorized-railway-cranes-and-lifts'
export const TRAMWAY_ACCIDENT_CRANES = 'tramway-accident-cranes'
export const OTHER_MOTORIZED_CRANES_AND_LIFTS = 'other-motorized-cranes-and-lifts'
export const CONTAINER_CRANES_AND_LOADERS = 'container-cranes-and-loaders'
export const MOTORIZED_STAND_JACKS = 'motorized-stand-jacks'
export const CHASSIS_HOISTS = 'chassis-hoists'
export const MOBILE_WORK_PLATFORMS_ON_RAIL = 'mobile-work-platforms-on-rail'
export const ELECTRIFIED_TRACK_PLATFORMS_ON_ROAD = 'electrified-track-platforms-on-road'

export const ALL_LIFT_DEVICE_TYPES = [
  RAILWAY_CRANES,
  MOTORIZED_RAILWAY_CRANES_AND_LIFTS,
  TRAMWAY_ACCIDENT_CRANES,
  OTHER_MOTORIZED_CRANES_AND_LIFTS,
  CONTAINER_CRANES_AND_LOADERS,
  MOTORIZED_STAND_JACKS,
  CHASSIS_HOISTS,
  MOBILE_WORK_PLATFORMS_ON_RAIL,
  ELECTRIFIED_TRACK_PLATFORMS_ON_ROAD,
] as const

export type LiftDeviceType = typeof ALL_LIFT_DEVICE_TYPES[number]
