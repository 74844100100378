export const CABLEWAY_MACHINERY_AND_STRUCTURES = 'cableway-machinery-and-structures'
export const ESCALATORS_AND_MOVING_WALKWAYS = 'escalators-and-moving-walkways'
export const ELECTRIC_PNEUMATIC_HYDRAULIC_ELEVATORS = 'electric-pneumatic-hydraulic-elevators'
export const PLATFORMS_FOR_MOBILITY_IMPAIRED = 'platforms-for-mobility-impaired'
export const SKI_AND_WATER_LIFTS = 'ski-and-water-lifts'

export const ALL_CABLE_DEVICE_TYPES = [
  CABLEWAY_MACHINERY_AND_STRUCTURES,
  ESCALATORS_AND_MOVING_WALKWAYS,
  ELECTRIC_PNEUMATIC_HYDRAULIC_ELEVATORS,
  PLATFORMS_FOR_MOBILITY_IMPAIRED,
  SKI_AND_WATER_LIFTS,
] as const

export type CableDeviceType = typeof ALL_CABLE_DEVICE_TYPES[number]
