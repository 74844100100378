export const TRACK = 'track'
export const ADDRESS = 'address'
export const STATION = 'station'
export const VEHICLE_NUMBER = 'vehicle-number'

export const ALL_DEVICE_LOCATIONS = [
  TRACK,
  ADDRESS,
  STATION,
  VEHICLE_NUMBER,
] as const

export type DeviceLocation = typeof ALL_DEVICE_LOCATIONS[number]
