import {extendApi} from '@anatine/zod-openapi'
import {ALL_APPS, OLUP, UTZ} from 'constants/apps'
import {ALL_ACTIVITY_TYPES} from 'constants/apps/olup/activityTypes'
import {ALL_CARRIER_NSA_TYPES} from 'constants/apps/olup/carrierNSATypes'
import {ALL_CHANGE_STATES} from 'constants/apps/olup/changeStates'
import {ALL_CHANGE_SUBJECT} from 'constants/apps/olup/changeSubjects'
import {ALL_CLOSED_AREAL_TYPES} from 'constants/apps/olup/closedArealTypes'
import {ALL_ETCS_TYPES} from 'constants/apps/olup/etcsTypes'
import {ALL_INFRASTRUCTURE_DOCUMENT_TYPES} from 'constants/apps/olup/infrastructureDocumentTypes'
import {ALL_LICENSE_CANCELLATION_REASONS} from 'constants/apps/olup/licenseCancellationReasons'
import {ALL_LICENSE_NSA_TYPES} from 'constants/apps/olup/licenseNSATypes'
import {ALL_MOUTHING_CATEGORIES} from 'constants/apps/olup/mouthingCategories'
import {ALL_PERMIT_CANCELLATION_REASONS} from 'constants/apps/olup/permitCancellationReasons'
import {ALL_PERMIT_TYPES} from 'constants/apps/olup/permitTypes'
import {ALL_TRACK_CATEGORIES} from 'constants/apps/olup/trackCategories'
import {ALL_TRACK_TYPES} from 'constants/apps/olup/trackTypes'
import {ALL_TRANSPORT_CATEGORIES} from 'constants/apps/olup/transportCategories'
import {ALL_TRANSPORT_TYPES} from 'constants/apps/olup/transportTypes'
import {ALL_CABLE_DEVICE_TYPES} from 'constants/apps/utz/cableDeviceTypes'
import {ALL_DEPARTURE_SIDE_TYPES} from 'constants/apps/utz/departureSideTypes'
import {ALL_DEVICE_LOCATIONS} from 'constants/apps/utz/deviceLocation'
import {ALL_DEVICE_TYPES} from 'constants/apps/utz/deviceTypes'
import {ALL_DRIVE_STATION_TYPES} from 'constants/apps/utz/driveStationTypes'
import {ALL_ELECTRONIC_DEVICE_TYPES} from 'constants/apps/utz/electronicDeviceTypes'
import {ALL_GAS_DEVICE_TYPES} from 'constants/apps/utz/gasDeviceTypes'
import {ALL_LIFT_CONTROLS} from 'constants/apps/utz/liftControls'
import {ALL_LIFT_DEVICE_TYPES} from 'constants/apps/utz/liftDeviceTypes'
import {ALL_OPERATION_MODE_TYPES} from 'constants/apps/utz/operationModeTypes'
import {ALL_PRESSURE_DEVICE_TYPES} from 'constants/apps/utz/pressureDeviceTypes'
import {ALL_TENSION_PULLEY_LOCATION_TYPES} from 'constants/apps/utz/tensionPulleyLocationTypes'
import {ALL_DEPARTMENTS} from 'constants/departments'
import {ALL_PERMISSIONS} from 'constants/permissions'
import {COMMON_RESOURCES, OLUP_RESOURCES, UTZ_RESOURCES} from 'constants/resources'
import {z} from 'zod'
import {createEnum, number} from './types'


// General
export const email = (example = 'example@example.com') => extendApi(z.string().email(), {example})

export const telephone = () => extendApi(
  z.string(),
  {example: '+421903123456'},
)

export const date = () => extendApi(
  z.string()
    .trim()
    .regex(/^\d{4}-(0[1-9]|1[0-2])-([0][1-9]|[12][0-9]|[3][01])$/),
  {example: '1980-03-24'},
)

export const coordinates = () => extendApi(
  z.tuple([z.tuple([number(), number()]), z.tuple([number(), number()])]),
)

export const appResource = () => z.union([
  z.object({app: z.literal(undefined).optional(), resource: createEnum(COMMON_RESOURCES)}),
  z.object({app: z.literal(OLUP), resource: createEnum(OLUP_RESOURCES)}),
  z.object({app: z.literal(UTZ), resource: createEnum(UTZ_RESOURCES)}),
])
export type AppResource = z.infer<ReturnType<typeof appResource>>

export const permissions = () => createEnum(ALL_PERMISSIONS)
export const permitType = () => createEnum(ALL_PERMIT_TYPES)
export const permitCancellationReason = () => createEnum(ALL_PERMIT_CANCELLATION_REASONS)
export const licenseCancellationReason = () => createEnum(ALL_LICENSE_CANCELLATION_REASONS)
export const department = () => createEnum(ALL_DEPARTMENTS)
export const trackType = () => createEnum(ALL_TRACK_TYPES)
export const transportType = () => createEnum(ALL_TRANSPORT_TYPES)
export const transportCategory = () => createEnum(ALL_TRANSPORT_CATEGORIES)
export const trackCategory = () => createEnum(ALL_TRACK_CATEGORIES)
export const mouthingCategory = () => createEnum(ALL_MOUTHING_CATEGORIES)
export const etcsType = () => createEnum(ALL_ETCS_TYPES)
export const closedArealType = () => createEnum(ALL_CLOSED_AREAL_TYPES)
export const infrastructureDocumentType = () => createEnum(ALL_INFRASTRUCTURE_DOCUMENT_TYPES)
export const licenseNSAType = () => createEnum(ALL_LICENSE_NSA_TYPES)
export const activityType = () => createEnum(ALL_ACTIVITY_TYPES)
export const carrierNSAType = () => createEnum(ALL_CARRIER_NSA_TYPES)
export const changeSubject = () => createEnum(ALL_CHANGE_SUBJECT)
export const changeState = () => createEnum(ALL_CHANGE_STATES)

export const deviceType = () => createEnum(ALL_DEVICE_TYPES)
export const electronicDeviceType = () => createEnum(ALL_ELECTRONIC_DEVICE_TYPES)
export const deviceLocation = () => createEnum(ALL_DEVICE_LOCATIONS)
export const pressureDeviceType = () => createEnum(ALL_PRESSURE_DEVICE_TYPES)
export const liftDeviceType = () => createEnum(ALL_LIFT_DEVICE_TYPES)
export const liftControlType = () => createEnum(ALL_LIFT_CONTROLS)
export const cableDeviceType = () => createEnum(ALL_CABLE_DEVICE_TYPES)
export const gasDeviceType = () => createEnum(ALL_GAS_DEVICE_TYPES)
export const operationModeType = () => createEnum(ALL_OPERATION_MODE_TYPES)
export const departureSideType = () => createEnum(ALL_DEPARTURE_SIDE_TYPES)
export const driveStationType = () => createEnum(ALL_DRIVE_STATION_TYPES)
export const tensionPulleyLocationType = () => createEnum(ALL_TENSION_PULLEY_LOCATION_TYPES)

export const app = () => createEnum(ALL_APPS)
