export const RC = 'RC'
export const ELECTRIC = 'electric'
export const HYDRAULIC = 'hydraulic'
export const MANUAL = 'manual'

export const ALL_LIFT_CONTROLS = [
  RC,
  ELECTRIC,
  HYDRAULIC,
  MANUAL,
] as const

export type LiftControlType = typeof ALL_LIFT_CONTROLS[number]
