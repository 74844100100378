export const SIDING = 'siding'
export const NATIONAL = 'national'
export const REGIONAL = 'regional'
export const LOCAL = 'local'
export const TEST = 'test'

export const ALL_TRACK_CATEGORIES = [
  SIDING,
  NATIONAL,
  REGIONAL,
  LOCAL,
  TEST,
] as const

export type TrackCategory = typeof ALL_TRACK_CATEGORIES[number]
