import {hasPermissions} from 'common/auth'
import type {App} from 'constants/apps'
import type {Permission} from 'constants/permissions'
import {useSession} from './auth'


class RouteError extends Error {
  status: number
  statusText: string
  internal: boolean
  data: string
}

type RouteErrorOptions = {
  statusText?: string
  status?: number
}

const throwRouteError = ({statusText = 'Not Found', status = 404}: RouteErrorOptions = {}) => {
  const error = new RouteError()
  error.status = status
  error.statusText = statusText
  error.internal = true
  error.data = ''
  throw error
}

type Options = {
  renderNotFound?: boolean
}

type Args = {
  permissions?: Permission[]
  app?: App
}

const usePermitted = ({permissions, app}: Args, options: Options = {renderNotFound: false}): boolean | undefined => {
  const session = useSession()
  const permitted = permissions ? hasPermissions(session?.permissions ? session.permissions : [], permissions) : true
  const permittedApp = app ? session?.apps?.includes(app) : true

  if (!permitted && options.renderNotFound) {
    throw throwRouteError()
  } 

  return permitted && permittedApp
}

export default usePermitted
