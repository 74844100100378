import * as apps from 'constants/apps'
import * as resources from 'constants/resources'
import type {CommonResource, OLUPResource, UTZResource} from 'constants/resources'
import invariant from 'invariant'
import type {AppResource} from './schemas/common'


const commonInvalidationMap = {
  [resources.ROLES]: [
    {resource: resources.USERS},
  ],
  [resources.USERS]: [
    {app: apps.OLUP, resource: resources.SUPERVISIONS},
    {app: apps.OLUP, resource: resources.TRACKS},
    {app: apps.OLUP, resource: resources.APPEALS},
    {app: apps.OLUP, resource: resources.CATEGORY_CHANGES},
    {app: apps.OLUP, resource: resources.FINES},
    {app: apps.OLUP, resource: resources.LICENSE_CHANGES},
    {app: apps.OLUP, resource: resources.PERMIT_CHANGES},
    {app: apps.OLUP, resource: resources.TRACK_CHANGES},

    {app: apps.UTZ, resource: resources.CABLES},
    {app: apps.UTZ, resource: resources.ELECTRONICS},
    {app: apps.UTZ, resource: resources.GASES},
    {app: apps.UTZ, resource: resources.LIFTS},
    {app: apps.UTZ, resource: resources.PRESSURES},
  ],
  [resources.COMPANIES]: [
    {resource: resources.COMPANY_REVISIONS},
    {app: apps.OLUP, resource: resources.LICENSES},
    {app: apps.OLUP, resource: resources.PERMITS},
    {app: apps.OLUP, resource: resources.TRACKS},
    {app: apps.OLUP, resource: resources.APPEALS},
    {app: apps.OLUP, resource: resources.CATEGORY_CHANGES},
    {app: apps.OLUP, resource: resources.FINES},

    {app: apps.UTZ, resource: resources.CABLES},
    {app: apps.UTZ, resource: resources.ELECTRONICS},
    {app: apps.UTZ, resource: resources.GASES},
    {app: apps.UTZ, resource: resources.LIFTS},
    {app: apps.UTZ, resource: resources.PRESSURES},
  ],
  [resources.COMPANY_REVISIONS]: [
    {resource: resources.COMPANIES},
  ],
} as const satisfies {[r in CommonResource]: AppResource[]}


const olupInvalidationMap = {
  [resources.FOREIGN_CARRIERS]: [
    {app: apps.OLUP, resource: resources.FOREIGN_CARRIER_REVISIONS},
  ],
  [resources.LICENSES]: [
    {app: apps.OLUP, resource: resources.LICENSE_REVISIONS},
    {app: apps.OLUP, resource: resources.TRACKS},
  ],
  [resources.PERMITS]: [
    {app: apps.OLUP, resource: resources.PERMIT_REVISIONS},
    {app: apps.OLUP, resource: resources.TRACKS},
  ],
  [resources.TRACKS]: [
    {app: apps.OLUP, resource: resources.TRACK_REVISIONS},
    {app: apps.OLUP, resource: resources.PERMITS},
    {app: apps.OLUP, resource: resources.SUPERVISIONS},
    {app: apps.OLUP, resource: resources.TRACK_CANCELLATIONS},
  ],
  [resources.SUPERVISIONS]: [
    {app: apps.OLUP, resource: resources.TRACKS},
  ],
  [resources.TRACK_CANCELLATIONS]: [
    {app: apps.OLUP, resource: resources.TRACKS},
    {app: apps.OLUP, resource: resources.TRACK_CANCELLATION_REVISIONS},
    {app: apps.OLUP, resource: resources.TRACK_REVISIONS},
  ],
  [resources.APPEALS]: [
    {app: apps.OLUP, resource: resources.APPEAL_REVISIONS},
  ],
  [resources.FINES]: [
    {app: apps.OLUP, resource: resources.FINE_REVISIONS},
  ],
  [resources.CATEGORY_CHANGES]: [
    {app: apps.OLUP, resource: resources.CATEGORY_CHANGE_REVISIONS},
  ],
  [resources.PERMIT_CHANGES]: [
    {app: apps.OLUP, resource: resources.PERMITS},
  ],
  [resources.LICENSE_CHANGES]: [
    {app: apps.OLUP, resource: resources.LICENSES},
  ],
  [resources.TRACK_CHANGES]: [
    {app: apps.OLUP, resource: resources.TRACKS},
  ],
  [resources.TRACK_REVISIONS]: [
    {app: apps.OLUP, resource: resources.TRACKS},
  ],
  [resources.PERMIT_REVISIONS]: [
    {app: apps.OLUP, resource: resources.PERMITS},
  ],
  [resources.LICENSE_REVISIONS]: [
    {app: apps.OLUP, resource: resources.LICENSES},
  ],
  [resources.FOREIGN_CARRIER_REVISIONS]: [
    {app: apps.OLUP, resource: resources.FOREIGN_CARRIERS},
  ],
  [resources.TRACK_CANCELLATION_REVISIONS]: [
    {app: apps.OLUP, resource: resources.TRACK_CANCELLATIONS},
  ],
  [resources.APPEAL_REVISIONS]: [
    {app: apps.OLUP, resource: resources.APPEALS},
  ],
  [resources.FINE_REVISIONS]: [
    {app: apps.OLUP, resource: resources.FINES},
  ],
  [resources.CATEGORY_CHANGE_REVISIONS]: [
    {app: apps.OLUP, resource: resources.CATEGORY_CHANGES},
  ],
} as const satisfies {[r in OLUPResource]: AppResource[]}

const utzInvalidationMap = {
  [resources.ELECTRONICS]: [
    {app: apps.UTZ, resource: resources.ELECTRONIC_REVISIONS},
  ],
  [resources.ELECTRONIC_REVISIONS]: [
    {app: apps.UTZ, resource: resources.ELECTRONICS},
  ],
  [resources.MANUFACTURERS]: [
    {app: apps.UTZ, resource: resources.CABLES},
    {app: apps.UTZ, resource: resources.DEVICES},
    {app: apps.UTZ, resource: resources.ELECTRONICS},
    {app: apps.UTZ, resource: resources.GASES},
    {app: apps.UTZ, resource: resources.LIFTS},
    {app: apps.UTZ, resource: resources.PRESSURES},
  ],
  [resources.DEVICES]: [
    {app: apps.UTZ, resource: resources.ELECTRONICS},
    {app: apps.UTZ, resource: resources.DEVICE_REVISIONS},
  ],
  [resources.DEVICE_REVISIONS]: [
    {app: apps.UTZ, resource: resources.DEVICES},
  ],
  [resources.PRESSURES]: [
    {resource: resources.USERS},
    {resource: resources.COMPANIES},
    {app: apps.UTZ, resource: resources.MANUFACTURERS},
    {app: apps.UTZ, resource: resources.PRESSURE_REVISIONS},
  ],
  [resources.PRESSURE_REVISIONS]: [
    {app: apps.UTZ, resource: resources.PRESSURES},
  ],
  [resources.LIFTS]: [
    {app: apps.UTZ, resource: resources.LIFT_REVISIONS},
  ],
  [resources.LIFT_REVISIONS]: [
    {app: apps.UTZ, resource: resources.LIFTS},
  ],
  [resources.CABLES]: [
    {app: apps.UTZ, resource: resources.CABLE_REVISIONS},
  ],
  [resources.CABLE_REVISIONS]: [
    {app: apps.UTZ, resource: resources.CABLES},
  ],
  [resources.GASES]: [
    {app: apps.UTZ, resource: resources.GAS_REVISIONS},
  ],
  [resources.GAS_REVISIONS]: [
    {app: apps.UTZ, resource: resources.GASES},
  ],
} as const satisfies {[r in UTZResource]: AppResource[]}

export const appInvalidationMap = {
  [apps.OLUP]: olupInvalidationMap,
  [apps.UTZ]: utzInvalidationMap,
}

const getInvalidatedAppResources = ((appResource: AppResource) => {
  if (!appResource.app) return commonInvalidationMap[appResource.resource]
  if (appResource.app === apps.OLUP) return olupInvalidationMap[appResource.resource]
  if (appResource.app === apps.UTZ) return utzInvalidationMap[appResource.resource]
  invariant(false, `Invalid app ${appResource satisfies never}`)
}) satisfies (appResource: AppResource) => AppResource[]

export default getInvalidatedAppResources
