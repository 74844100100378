export const COMPANIES = 'companies'
export const FOREIGN_CARRIERS = 'foreignCarriers'
export const LICENSES = 'licenses'
export const PERMITS = 'permits'
export const ROLES = 'roles'
export const TRACKS = 'tracks'
export const SUPERVISIONS = 'supervisions'
export const USERS = 'users'
export const TRACK_CANCELLATIONS = 'trackCancellations'
export const APPEALS = 'appeals'
export const FINES = 'fines'
export const CATEGORY_CHANGES = 'categoryChanges'
export const PERMIT_CHANGES = 'permitChanges'
export const LICENSE_CHANGES = 'licenseChanges'
export const TRACK_CHANGES = 'trackChanges'

export const DEVICES = 'devices'
export const ELECTRONICS = 'electronics'
export const MANUFACTURERS = 'manufacturers'
export const PRESSURES = 'pressures'
export const LIFTS = 'lifts'
export const CABLES = 'cables'
export const GASES = 'gases'

export const DEVICE_REVISIONS = 'deviceRevisions'
export const ELECTRONIC_REVISIONS = 'electronicRevisions'
export const PRESSURE_REVISIONS = 'pressureRevisions'
export const LIFT_REVISIONS = 'liftRevisions'
export const CABLE_REVISIONS = 'cableRevisions'
export const GAS_REVISIONS = 'gasRevisions'

export const TRACK_REVISIONS = 'trackRevisions'
export const PERMIT_REVISIONS = 'permitRevisions'
export const LICENSE_REVISIONS = 'licenseRevisions'
export const COMPANY_REVISIONS = 'companyRevisions'
export const FOREIGN_CARRIER_REVISIONS = 'foreignCarrierRevisions'
export const TRACK_CANCELLATION_REVISIONS = 'trackCancellationRevisions'
export const APPEAL_REVISIONS = 'appealRevisions'
export const FINE_REVISIONS = 'fineRevisions'
export const CATEGORY_CHANGE_REVISIONS = 'categoryChangeRevisions'

export const OLUP_RESOURCES = [
  FOREIGN_CARRIERS,
  LICENSES,
  PERMITS,
  TRACKS,
  SUPERVISIONS,
  TRACK_CANCELLATIONS,
  APPEALS,
  FINES,
  CATEGORY_CHANGES,
  PERMIT_CHANGES,
  LICENSE_CHANGES,
  TRACK_CHANGES,
  TRACK_REVISIONS,
  PERMIT_REVISIONS,
  LICENSE_REVISIONS,
  FOREIGN_CARRIER_REVISIONS,
  TRACK_CANCELLATION_REVISIONS,
  APPEAL_REVISIONS,
  FINE_REVISIONS,
  CATEGORY_CHANGE_REVISIONS,
] as const

export type OLUPResource = typeof OLUP_RESOURCES[number]

export const UTZ_RESOURCES = [
  CABLES,
  CABLE_REVISIONS,
  DEVICES,
  DEVICE_REVISIONS,
  ELECTRONICS,
  ELECTRONIC_REVISIONS,
  GASES,
  GAS_REVISIONS,
  LIFTS,
  LIFT_REVISIONS,
  MANUFACTURERS,
  PRESSURES,
  PRESSURE_REVISIONS,
] as const

export type UTZResource = typeof UTZ_RESOURCES[number]

export const COMMON_RESOURCES = [
  USERS,
  COMPANIES,
  COMPANY_REVISIONS,
  ROLES,
] as const

export type CommonResource = typeof COMMON_RESOURCES[number]

export const ALL_RESOURCES = [
  ...OLUP_RESOURCES,
  ...UTZ_RESOURCES,
  ...COMMON_RESOURCES,
] as const

export type Resource = typeof ALL_RESOURCES[number]
